import type { ReactNode } from 'react';
import type React from 'react';
import {clsx} from "../utils/styles";
import {Button} from "./inputs/Button";

export interface ModalProps {
	isOpen: boolean;
	title?: string;
	onClose: () => void;
	className?: string;
	hideCloseButton?: boolean;
	children?: ReactNode;
}

export const Modal: React.FC<ModalProps> = (props) => {
	const { isOpen, onClose, children, className, hideCloseButton } = props;

	return (
		<dialog className={clsx('modal', { 'modal-open': isOpen })}>
			<div className={clsx('modal-box relative', className)}>
				{!hideCloseButton && (
					<Button
						type="button"
						onClick={onClose}
						className="btn btn-circle btn-sm absolute right-2 top-2"
					>
						✕
					</Button>
				)}
				{children}
			</div>
			<form method="dialog" className="modal-backdrop">
				<button type="submit">close</button>
			</form>
		</dialog>
	);
};
